import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
// import Image from "../components/image"
// import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    {/* <SEO title="Nambee" keywords={[`Nambee`, `jewellery`, `jewelry`, 'bees', 'charity']} /> */}
    <h1>Coming Soon</h1>
    {/* <Link to="/page-2/">Page 2</Link>
    <br></br>
    <Link to="/about/">About</Link> */}
  </Layout>
)

export default IndexPage
